import { createSelector } from 'reselect';
const authSelect = (state) => {
    console.log('state', state.auth);
    return state.auth};

export const selectAuth = createSelector([authSelect], (auth) => {
    console.log('auth', auth);
    return auth;
});
export const selectCurrentAdmin = createSelector([authSelect], (auth) => auth);

export const isLoggedIn = createSelector([authSelect], (auth) => auth?.email !== null);
